<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`dynamicComponent-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
// layouts
import FooterOne from '~/components/theme7/base/footer/FooterOne.vue';
import HeaderOne from '~/components/theme7/base/header/HeaderOne.vue';

// library components
import FormContainer from '~/components/library/FormContainer.vue';
import Properties from '~/components/library/Properties.vue';
import Register from '~/components/common/register/Register.vue';

// theme7 components
import Test from '~/components/theme7/components/Test.vue';
import ScrollAnimationTexts from '~/components/theme7/components/ScrollAnimationTexts.vue';
import OurValuations from '~/components/theme7/components/OurValuations.vue';
import TakeAction from '~/components/theme7/components/TakeAction.vue';

// common
import RegisterEntry from '~/components/common/register/RegisterEntry.vue';
import PropertiesEntry from '~/components/common/properties/PropertiesEntry.vue';
import Separator from '~/components/library/Separator.vue';
import RedirectTo from '~/components/common/RedirectTo.vue';

// blogs components
import {
  LibraryPropertiesOnTheFlySearchBox,
  Theme7BlogsPage,
  Theme7BlogsOurBlog,
  Theme7PropertiesLatestProperties,
  Theme7PropertiesPropertyDetail,
  Theme7BlogsBlogDetail,
} from '#components';
import UpdateProfileEntry from '~/components/library/heads_up_alerts/UpdateProfileEntry.vue';
import LoginBox from '~/components/library/LoginBox.vue';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },
  },

  components: {
    HeaderOne,
    FooterOne,
    FormContainer,
    Properties,
    Register,
    RegisterEntry,
    PropertiesEntry,
    Separator,
    RedirectTo,
    OnTheFlyPropertySearchBox: LibraryPropertiesOnTheFlySearchBox,
    PropertyDetail: Theme7PropertiesPropertyDetail,
    LatestProperties: Theme7PropertiesLatestProperties,
    OurBlog: Theme7BlogsOurBlog,
    BlogsPage: Theme7BlogsPage,
    BlogDetail: Theme7BlogsBlogDetail,
    UpdateProfileEntry,
    LoginBox,
    Test,
    ScrollAnimationTexts,
    OurValuations,
    TakeAction,
  },
});
</script>
