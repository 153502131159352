<template>
  <span class="block" style="font-family: var(--f-primary)" :style="getPositionStyle" :class="getPositionClass">
    {{ blockSymbol }}
  </span>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'BlockSymbol',

  props: {
    positionText: {
      type: String,
      default: 'right-bottom',
    },
    blockColor: {
      type: String,
      default: 'primary',
    },
    blockSymbol: {
      type: String,
      default: '&',
    },
  },

  computed: {
    getPositionClass(): string {
      // @ts-ignore - props are correctly defined above
      const position = this.positionText || 'right-bottom';

      switch (position) {
        case 'left-bottom':
          return '-tw-bottom-12 -tw-left-8';
        case 'right-bottom':
          return '-tw-bottom-12 -tw-right-8';
        case 'left-top':
          return '-tw-top-12 -tw-left-8';
        case 'right-top':
          return '-tw-top-12 -tw-right-8';
        case 'left-center':
          return '-tw-top-12 -tw-left-8';
        case 'right-center':
          return 'tw-top-1/2 -tw-right-8 tw-transform -tw-translate-y-1/2';
        default:
          return '-tw-bottom-12 -tw-right-8';
      }
    },
    getPositionStyle(): string {
      // @ts-ignore - props are correctly defined above
      const color = this.blockColor && this.blockColor !== '' ? this.blockColor : 'primary';
      return `color: var(--c-${color});`;
    },
  },
});
</script>

<style scoped>
.block {
  font-size: 24em;
  font-weight: 900;
  color: var(--c-secondary);
  line-height: 0.8;
  z-index: 1;
  margin: 0;
  padding: 0;
  position: absolute;
}
</style>
