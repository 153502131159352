<template>
  <section
    class="n-section-primary tw-relative tw-bg-cover tw-bg-center"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <BlockSymbol :position-text="String(vars?.blockPositionText || '')" :block-color="String(vars?.blockColor || '')" />
    <div class="tw-absolute tw-inset-0 tw-opacity-80" :style="`background-color: var(--c-secondary);`"></div>
    <div class="tw-z-10 tw-flex tw-flex-col tw-items-center tw-text-center">
      <h3
        class="text-40 md:text-38 uppercase fw-500 tw-pb-8"
        :style="`${vars.textColor ? `color: var(--c-${vars.textColor})` : ''}`"
      >
        {{ vars.titleText }}
      </h3>
      <div v-if="descriptions" class="tw-space-y-1">
        <p
          v-for="({ description }, index) in descriptions"
          :key="`takeaction-description-${index}`"
          class="tw-max-w-2xl"
          :style="`${vars.textColor ? `color: var(--c-${vars.textColor}) !important` : ''}`"
        >
          {{ description }}
        </p>
      </div>
      <div v-if="buttons" class="tw-m-6 tw-flex tw-flex-wrap tw-justify-center tw-gap-2">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`takeaction-button-${index}`"
          :to="button.link"
          :class="`n-${button.type}`"
          >{{ button.label }}</nuxt-link
        >
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import BlockSymbol from '~/components/common/BlockSymbol.vue';

export default defineNuxtComponent({
  name: 'TakeAction',

  components: {
    BlockSymbol,
  },

  mixins: [ComponentMixin],

  computed: {
    descriptions(): Array<{ description: string }> {
      // @ts-ignore - groupedVariables is provided by ComponentMixin
      return this.groupedVariables?.descriptions || [];
    },
    buttons(): Array<{ link: string; type: string; label: string }> {
      // @ts-ignore - groupedVariables is provided by ComponentMixin
      return this.groupedVariables?.buttons || [];
    },
  },
});
</script>

<style scoped>
.block {
  font-size: 24em;
  font-weight: 900;
  color: var(--c-secondary);
  line-height: 0.8;
  z-index: 1;
  margin: 0;
  padding: 0;
  position: absolute;
}
</style>
