<template>
  <section class="layout-pt-lg layout-pb-lg" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="container">
      <div class="row justify-between items-center">
        <div class="col-auto">
          <h2 class="text-62 md:text-38 uppercase fw-500" v-html="vars.titleHtml"></h2>
        </div>

        <div class="col-xl-6 md:d-none">
          <p class="">
            {{ vars.descriptionText }}
          </p>
        </div>
      </div>

      <div
        class="relative mt-60 md:mt-40 sm:mt-20 js-section-slider"
        data-anim="slide-left delay-1"
        data-gap="30"
        data-slider-cols="xl-3 lg-2 md-1 sm-1 base-1"
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide tw-border-2 tw-p-2" v-for="(card, index) in cards" :key="index">
            <nuxt-link
              :to="card.link"
              :external="true"
              class="imageCard -type-2 hover-btn-explore n-link tw-flex tw-flex-col md:tw-min-h-[600px]"
            >
              <div class="tw-mb-4">
                <library-image :src="card.photo" alt="image" class="tw-h-full tw-w-full tw-object-cover" />
              </div>

              <div class="tw-flex tw-flex-1 tw-flex-col">
                <h6 class="imageCard__title text-dark-1 tw-line-clamp-1">
                  <b>{{ card.title }}</b>
                </h6>
                <p class="imageCard__text text-dark-1 tw-flex-1">{{ card.description }}</p>

                <button :class="`n-${card.button_type}`" class="tw-mt-auto">
                  {{ card.button_label }}
                </button>
              </div>
            </nuxt-link>
          </div>
        </div>

        <div class="d-flex mt-60 md:mt-30 lg:tw-hidden">
          <button class="sliderNav -prev js-prev">
            <i class="icon-left-arrow"></i>
          </button>
          <button class="sliderNav -next ml-20 js-next">
            <i class="icon-right-arrow"></i>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'Theme7OurValuations',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
      },
    };
  },

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },
});
</script>
