<template>
  <div
    class="property-list-item tw-flex tw-flex-col tw-px-5 tw-py-6 tw-text-start md:tw-min-h-[700px] md:tw-p-6"
    style="border-radius: var(--rounded)"
  >
    <nuxt-link
      :external="true"
      :to="property.detail_page_url"
      class="n-link tw-group tw-relative hover:tw-text-[unset]"
    >
      <div
        class="tw-relative tw-border-b-4 tw-border-l-4"
        :class="{
          'tw-border-[var(--c-secondary)]': !property.is_rent,
          'tw-border-[var(--c-tertiary)]': property.is_rent,
        }"
      >
        <LibraryImage
          :src="property.image"
          :h-ratio="10"
          :w-ratio="16"
          class="card-img tw-overflow-hidden"
          style="border-radius: var(--rounded)"
        />
        <div
          v-if="property.custom_property_status_title"
          class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black/50"
        >
          <div class="tw-text-3xl tw-font-bold tw-text-white">
            {{ property.custom_property_status_title }}
          </div>
        </div>
      </div>
      <div class="tw-mt-5">
        <h5 class="n-title-primary tw-line-clamp-3 tw-h-[94px]">{{ property.advert_heading }}</h5>
      </div>
      <div class="tw-mt-5 tw-flex tw-h-8 tw-flex-row tw-items-center tw-gap-2">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="tw-shrink-0"
        >
          <path
            d="M10 11.25C9.38193 11.25 8.77775 11.0667 8.26384 10.7233C7.74994 10.38 7.3494 9.89191 7.11288 9.32089C6.87635 8.74987 6.81447 8.12154 6.93505 7.51534C7.05563 6.90915 7.35325 6.35233 7.79029 5.91529C8.22733 5.47825 8.78415 5.18063 9.39034 5.06005C9.99654 4.93947 10.6249 5.00135 11.1959 5.23788C11.7669 5.4744 12.255 5.87494 12.5983 6.38884C12.9417 6.90275 13.125 7.50693 13.125 8.125C13.124 8.9535 12.7945 9.74778 12.2086 10.3336C11.6228 10.9195 10.8285 11.249 10 11.25ZM10 6.25C9.62916 6.25 9.26665 6.35997 8.95831 6.566C8.64997 6.77202 8.40964 7.06486 8.26773 7.40747C8.12581 7.75008 8.08868 8.12708 8.16103 8.4908C8.23338 8.85451 8.41195 9.1886 8.67418 9.45083C8.9364 9.71305 9.27049 9.89163 9.63421 9.96397C9.99792 10.0363 10.3749 9.99919 10.7175 9.85728C11.0601 9.71536 11.353 9.47504 11.559 9.1667C11.765 8.85835 11.875 8.49584 11.875 8.125C11.8745 7.62787 11.6768 7.15125 11.3253 6.79972C10.9738 6.4482 10.4971 6.2505 10 6.25Z"
            :class="{
              'tw-fill-[var(--c-secondary)]': !property.is_rent,
              'tw-fill-[var(--c-tertiary)]': property.is_rent,
            }"
          />
          <path
            d="M10 18.75L4.72751 12.5319C4.65425 12.4385 4.58174 12.3445 4.51001 12.25C3.60937 11.0636 3.12282 9.61452 3.12501 8.125C3.12501 6.30164 3.84934 4.55295 5.13865 3.26364C6.42796 1.97433 8.17664 1.25 10 1.25C11.8234 1.25 13.5721 1.97433 14.8614 3.26364C16.1507 4.55295 16.875 6.30164 16.875 8.125C16.8772 9.61384 16.3909 11.0623 15.4906 12.2481L15.49 12.25C15.49 12.25 15.3025 12.4963 15.2744 12.5294L10 18.75ZM5.50813 11.4969C5.50813 11.4969 5.65376 11.6894 5.68688 11.7306L10 16.8175L14.3188 11.7237C14.3463 11.6894 14.4925 11.4956 14.4931 11.495C15.2289 10.5257 15.6265 9.34189 15.625 8.125C15.625 6.63316 15.0324 5.20242 13.9775 4.14752C12.9226 3.09263 11.4918 2.5 10 2.5C8.50816 2.5 7.07742 3.09263 6.02253 4.14752C4.96764 5.20242 4.37501 6.63316 4.37501 8.125C4.3737 9.34265 4.77174 10.5271 5.50813 11.4969Z"
            :class="{
              'tw-fill-[var(--c-secondary)]': !property.is_rent,
              'tw-fill-[var(--c-tertiary)]': property.is_rent,
            }"
          />
        </svg>
        <small>{{ property.display_address }}</small>
      </div>
      <div class="tw-mt-6 tw-flex tw-flex-wrap tw-gap-x-6 tw-gap-y-3">
        <div v-if="property.bedroom">
          <small class="tw-flex tw-items-center tw-gap-2"
            ><span
              ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 19V13C2 12.55 2.09167 12.1417 2.275 11.775C2.45833 11.4083 2.7 11.0833 3 10.8V8C3 7.16667 3.29167 6.45833 3.875 5.875C4.45833 5.29167 5.16667 5 6 5H10C10.3833 5 10.7417 5.071 11.075 5.213C11.4083 5.355 11.7167 5.55067 12 5.8C12.2833 5.55 12.5917 5.35433 12.925 5.213C13.2583 5.07167 13.6167 5.00067 14 5H18C18.8333 5 19.5417 5.29167 20.125 5.875C20.7083 6.45833 21 7.16667 21 8V10.8C21.3 11.0833 21.5417 11.4083 21.725 11.775C21.9083 12.1417 22 12.55 22 13V19H20V17H4V19H2ZM13 10H19V8C19 7.71667 18.904 7.47933 18.712 7.288C18.52 7.09667 18.2827 7.00067 18 7H14C13.7167 7 13.4793 7.096 13.288 7.288C13.0967 7.48 13.0007 7.71733 13 8V10ZM5 10H11V8C11 7.71667 10.904 7.47933 10.712 7.288C10.52 7.09667 10.2827 7.00067 10 7H6C5.71667 7 5.47933 7.096 5.288 7.288C5.09667 7.48 5.00067 7.71733 5 8V10ZM4 15H20V13C20 12.7167 19.904 12.4793 19.712 12.288C19.52 12.0967 19.2827 12.0007 19 12H5C4.71667 12 4.47933 12.096 4.288 12.288C4.09667 12.48 4.00067 12.7173 4 13V15Z"
                  :class="{
                    'tw-fill-[var(--c-secondary)]': !property.is_rent,
                    'tw-fill-[var(--c-tertiary)]': property.is_rent,
                  }"
                />
              </svg> </span
            >{{ property.bedroom }} Beds</small
          >
        </div>
        <div v-if="property.bathroom">
          <small class="tw-flex tw-items-center tw-gap-2"
            ><span
              ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 6C7 5.73478 7.10536 5.48043 7.29289 5.29289C7.48043 5.10536 7.73478 5 8 5H9V6C9 6.26522 9.10536 6.51957 9.29289 6.70711C9.48043 6.89464 9.73478 7 10 7C10.2652 7 10.5196 6.89464 10.7071 6.70711C10.8946 6.51957 11 6.26522 11 6V5C11 4.46957 10.7893 3.96086 10.4142 3.58579C10.0391 3.21071 9.53043 3 9 3H8C7.20435 3 6.44129 3.31607 5.87868 3.87868C5.31607 4.44129 5 5.20435 5 6V11H4C3.46957 11 2.96086 11.2107 2.58579 11.5858C2.21071 11.9609 2 12.4696 2 13V14C1.99996 14.9783 2.23913 15.9417 2.69665 16.8064C3.15417 17.671 3.81618 18.4107 4.625 18.961L4.293 19.293C4.19749 19.3852 4.12131 19.4956 4.0689 19.6176C4.01649 19.7396 3.9889 19.8708 3.98775 20.0036C3.9866 20.1364 4.0119 20.2681 4.06218 20.391C4.11246 20.5138 4.18671 20.6255 4.2806 20.7194C4.3745 20.8133 4.48615 20.8875 4.60905 20.9378C4.73194 20.9881 4.86362 21.0134 4.9964 21.0123C5.12918 21.0111 5.2604 20.9835 5.3824 20.9311C5.50441 20.8787 5.61475 20.8025 5.707 20.707L6.583 19.832C7.037 19.942 7.512 20 8 20H16C16.488 20 16.963 19.942 17.417 19.832L18.293 20.707C18.4816 20.8892 18.7342 20.99 18.9964 20.9877C19.2586 20.9854 19.5094 20.8802 19.6948 20.6948C19.8802 20.5094 19.9854 20.2586 19.9877 19.9964C19.99 19.7342 19.8892 19.4816 19.707 19.293L19.375 18.961C20.1838 18.4107 20.8458 17.671 21.3034 16.8064C21.7609 15.9417 22 14.9783 22 14V13C22 12.4696 21.7893 11.9609 21.4142 11.5858C21.0391 11.2107 20.5304 11 20 11H7V6ZM4 13H20V14C20 15.0609 19.5786 16.0783 18.8284 16.8284C18.0783 17.5786 17.0609 18 16 18H8C6.93913 18 5.92172 17.5786 5.17157 16.8284C4.42143 16.0783 4 15.0609 4 14V13Z"
                  :class="{
                    'tw-fill-[var(--c-secondary)]': !property.is_rent,
                    'tw-fill-[var(--c-tertiary)]': property.is_rent,
                  }"
                />
              </svg> </span
            >{{ property.bathroom }} Baths</small
          >
        </div>
        <div v-if="property.reception">
          <small class="tw-flex tw-items-center tw-gap-2"
            ><span
              ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.556 18H18.444C19.3871 18 20.2916 17.6254 20.9585 16.9585C21.6254 16.2916 22 15.3871 22 14.444V12C22 11.4696 21.7893 10.9609 21.4142 10.5858C21.0391 10.2107 20.5304 10 20 10C19.4696 10 18.9609 10.2107 18.5858 10.5858C18.2107 10.9609 18 11.4696 18 12V13.2C18 13.4122 17.9157 13.6157 17.7657 13.7657C17.6157 13.9157 17.4122 14 17.2 14H6.8C6.58783 14 6.38434 13.9157 6.23431 13.7657C6.08429 13.6157 6 13.4122 6 13.2V12C6 11.4696 5.78929 10.9609 5.41421 10.5858C5.03914 10.2107 4.53043 10 4 10C3.46957 10 2.96086 10.2107 2.58579 10.5858C2.21071 10.9609 2 11.4696 2 12V14.444C2 15.3871 2.37465 16.2916 3.04153 16.9585C3.70841 17.6254 4.61289 18 5.556 18Z"
                  :class="{
                    'tw-stroke-[var(--c-secondary)]': !property.is_rent,
                    'tw-stroke-[var(--c-tertiary)]': property.is_rent,
                  }"
                  stroke-width="2"
                />
                <path
                  d="M20 10C20 9.07 20 8.606 19.923 8.22C19.7687 7.44401 19.3877 6.73121 18.8282 6.17175C18.2688 5.6123 17.556 5.23132 16.78 5.077C16.394 5 15.93 5 15 5H9C8.07 5 7.606 5 7.22 5.077C6.44401 5.23132 5.73121 5.6123 5.17175 6.17175C4.6123 6.73121 4.23132 7.44401 4.077 8.22C4 8.606 4 9.07 4 10"
                  :class="{
                    'tw-stroke-[var(--c-secondary)]': !property.is_rent,
                    'tw-stroke-[var(--c-tertiary)]': property.is_rent,
                  }"
                  stroke-width="2"
                />
                <path
                  d="M20 19V18M4 19V18"
                  :class="{
                    'tw-stroke-[var(--c-secondary)]': !property.is_rent,
                    'tw-stroke-[var(--c-tertiary)]': property.is_rent,
                  }"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg> </span
            >{{ property.reception }} Reception</small
          >
        </div>
        <div v-if="property.floor_area">
          <small class="tw-flex tw-items-center tw-gap-2"
            ><span
              ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V5C21 3.897 20.103 3 19 3H5C3.897 3 3 3.897 3 5ZM19.002 19H5V5H19L19.002 19Z"
                  :class="{
                    'tw-fill-[var(--c-secondary)]': !property.is_rent,
                    'tw-fill-[var(--c-tertiary)]': property.is_rent,
                  }"
                />
                <path
                  d="M15 12H17V7H12V9H15V12ZM12 15H9V12H7V17H12V15Z"
                  :class="{
                    'tw-fill-[var(--c-secondary)]': !property.is_rent,
                    'tw-fill-[var(--c-tertiary)]': property.is_rent,
                  }"
                />
              </svg> </span
            >{{ property.floor_area }}</small
          >
        </div>
      </div>
      <div class="tw-mt-6">
        <p class="tw-mb-2">
          {{ property.price.type?.name || '\u00A0' }}
        </p>
        <h4>{{ property.price.price }}</h4>
      </div>

      <button
        class="tw-mt-8"
        :class="{
          'n-secondary outlined': !property.is_rent,
          'n-tertiary outlined': property.is_rent,
        }"
      >
        VIEW THIS PROPERTY
      </button>
    </nuxt-link>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { PropertyItemComponent } from '~/units/properties/types';
import NcTooltip from '~/components/common/NcTooltip.vue';

export default defineNuxtComponent({
  name: 'Theme7PropertyListItem',

  components: { NcTooltip },

  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },

    isMatchInformationHidden: {
      required: false,
      type: Boolean,
      default: false,
    },

    version: {
      required: false,
      type: String,
      default: 'v1',
    },

    preMarketPropertyFeature: {
      required: false,
      type: Boolean,
      default: false,
    },

    colSpan: {
      required: false,
      type: Number,
      default: 4,
    },

    isLetterPropertyCriteria: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
});
</script>
