<template>
  <section class="layout-pt-lg layout-pb-lg" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="container">
      <div class="row justify-between items-center">
        <div class="col-auto">
          <h2 class="text-62 md:text-38 uppercase fw-500" v-html="vars.titleHtml" />
        </div>

        <div class="col-xl-5 tw-hidden tw-gap-2 xl:tw-flex">
          <nuxt-link
            v-for="button in buttons"
            :key="`latestproperties-button-${button.label}`"
            :to="button.link"
            :external="true"
            :class="`n-${button.type}`"
          >
            {{ button.label }}
          </nuxt-link>
        </div>
      </div>

      <div
        class="relative mt-60 md:mt-40 sm:mt-20 js-section-slider"
        data-anim="slide-left delay-1"
        data-gap="30"
        data-slider-cols="xl-3 lg-2 md-1 sm-1 base-1"
      >
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(property, index) in mixedProperties"
            :key="`latestproperties-property-${index}`"
          >
            <theme7-properties-property-list-item
              :property="property"
              :is-match-information-hidden="true"
              :pre-market-property-feature="true"
            />
          </div>
        </div>

        <div class="d-flex mt-60 md:mt-30">
          <button class="sliderNav -prev js-prev">
            <i class="icon-left-arrow"></i>
          </button>
          <button class="sliderNav -next ml-20 js-next">
            <i class="icon-right-arrow"></i>
          </button>
        </div>
      </div>

      <div class="tw-flex tw-w-full tw-flex-col tw-gap-2 tw-pt-10 lg:tw-flex-row xl:tw-hidden">
        <nuxt-link
          v-for="button in buttons"
          :key="`latestproperties-button-${button.label}`"
          :to="button.link"
          :external="true"
          :class="`n-${button.type}`"
        >
          {{ button.label }}
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { PropertiesMixin } from '~/units/properties/mixins/properties.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

interface ComponentData {
  swiper: any | null;
  canPaginateBack: boolean;
  selectedProperty: any | null;
  isVisible: boolean;
  canPaginateNext: boolean;
  variants: {
    v1: string;
    v2: string;
  };
}

export default defineNuxtComponent({
  name: 'Theme7LatestProperties',

  mixins: [ComponentMixin, PropertiesMixin],

  async setup(props: any) {
    try {
      await fetchPropertiesManager(props.component, 'both');
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  },

  data(): ComponentData {
    return {
      swiper: null,
      canPaginateBack: true,
      selectedProperty: null,
      isVisible: false,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  mounted() {
    try {
      this.onSlideChange();
    } catch (error) {
      console.error('Error in mounted hook:', error);
    }
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),
    loopedProperties() {
      try {
        return this.properties ? [...this.properties, ...this.properties] : [];
      } catch (error) {
        console.error('Error in loopedProperties computed:', error);
        return [];
      }
    },

    mixedProperties() {
      try {
        if (!this.properties || this.properties.length === 0) return [];

        const rentProperties = this.properties.filter((property) => property.is_rent);
        const saleProperties = this.properties.filter((property) => !property.is_rent);

        const result = [];
        const maxLength = Math.max(rentProperties.length, saleProperties.length);

        for (let i = 0; i < maxLength; i++) {
          if (i < saleProperties.length) result.push(saleProperties[i]);
          if (i < rentProperties.length) result.push(rentProperties[i]);
        }

        return result;
      } catch (error) {
        console.error('Error in mixedProperties computed:', error);
        return [];
      }
    },

    buttons() {
      return this.groupedVariables.buttons;
    },
  },

  methods: {
    onSwiper(swiper: any) {
      try {
        if (swiper) {
          this.swiper = swiper;
        }
      } catch (error) {
        console.error('Error in onSwiper:', error);
      }
    },

    onSlideChange() {
      try {
        if (!this.swiper) return;

        const isAtStart = this.swiper.isBeginning;
        const isAtEnd = this.swiper.isEnd;

        this.canPaginateBack = !isAtStart;
        this.canPaginateNext = !isAtEnd;
      } catch (error) {
        console.error('Error in onSlideChange:', error);
      }
    },
  },
});
</script>

<style scoped>
::v-deep(.v3-left-arrow),
::v-deep(.v3-right-arrow) {
  margin-top: -100px;
}

::v-deep(.property-list-item) {
  min-height: unset !important;
}
.sidebar ::v-deep(.property-list-item) {
  background-color: var(--c-quaternary) !important;
}
</style>
